import React, { Fragment } from "react"
import LayoutGetStarted from "../components/layoutGetStarted"
import { Link } from "gatsby"
import Meta from "../components/meta"

export default function CancelPage() {
  return (
    <Fragment>
      <Meta title="Cancel" />
      <LayoutGetStarted activeScreen={5} setActiveScreen={() => { }}>
        <div className="grid place-items-center pt-10">
          <div className="text-xl font-medium pb-5">You cancelled your order!</div>
          <div className="pt-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
              <path d="M10.975 8l.025-.5c0-.517-.067-1.018-.181-1.5h5.993l-.564 2h-5.273zm-2.475 10c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-10.428l-.455-1.083c-.323.049-.653.083-.99.083-.407 0-.805-.042-1.191-.114l1.306 3.114h13.239l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-4.5-10.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.707 1.414 1.415-1.414 1.414.707.708 1.414-1.415 1.414 1.414.708-.708-1.414-1.413 1.414-1.414-.708-.708z" />
            </svg>
          </div>
          <div className="text-md pt-10"><Link className="font-medium text-delidoor-dark" to="/">Click</Link> to restart.</div>
        </div>
      </LayoutGetStarted>
    </Fragment>
  )
}